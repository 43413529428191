import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

declare const JitsiMeetExternalAPI: any;

function ConferenceCall({
  roomName,
  email,
  displayName,
  meetingPassword,
  onMeetingEnd,
  isProvider = false,
}: {
  roomName: string;
  email: string;
  displayName: string;
  meetingPassword: string;
  isProvider?: boolean;
  onMeetingEnd?: () => void;
}) {
  const callLogo =
    "https://humantold.com/wp-content/themes/humantold/assets/images/human-told-logo.svg?1604507125";
  const history = useHistory();
  useEffect(() => {
    const domain = "meet.jit.si"; // TODO: Put in env.
    const options = {
      roomName,
      width: 950,
      height: 700,
      parentNode: document.querySelector("#conference-call-window"),
      noSSL: false,
      userInfo: {
        email,
        displayName,
      },
      // https://github.com/jitsi/jitsi-meet/blob/master/config.js
      configOverwrite: {
        enableWelcomePage: false,
        prejoinPageEnabled: false,
        startWithAudioMuted: true,
        startWithVideoMuted: true,
      },
      // https://github.com/jitsi/jitsi-meet/blob/master/interface_config.js
      interfaceConfigOverwrite: {
        MOBILE_APP_PROMO: false,
        DEFAULT_LOGO_URL: callLogo,
        JITSI_WATERMARK_LINK: callLogo,
        DEFAULT_WELCOME_PAGE_LOGO_URL: callLogo,
        DEFAULT_BACKGROUND: "#38B2AC",
        DISPLAY_WELCOME_FOOTER: false,
        HIDE_DEEP_LINKING_LOGO: true,
        SHOW_CHROME_EXTENSION_BANNER: false,
        CLOSE_PAGE_GUEST_HINT: false,
        HIDE_INVITE_MORE_HEADER: true,
        TOOLBAR_BUTTONS: [
          "microphone",
          "camera",
          "closedcaptions",
          "desktop",
          "fullscreen",
          "fodeviceselection",
          "hangup",
          "profile",
          "chat",
          "etherpad",
          "raisehand",
          "videoquality",
          "filmstrip",
          "tileview",
          "mute-everyone",
        ],
      },
    };
    const api = new JitsiMeetExternalAPI(domain, options);

    api.on("passwordRequired", function () {
      api.executeCommand("password", meetingPassword);
    });

    api.on("videoConferenceJoined", function () {
      // Registering this handler here because it conflicts with the passwordrequired event
      // and closes the conference just before the passwordRequired event is trigerred
      api.on("videoConferenceLeft", function () {
        api.dispose();
        if (onMeetingEnd) {
          onMeetingEnd();
        } else {
          history.push(`/`);
        }
      });
    });
  }, [
    roomName,
    displayName,
    email,
    isProvider,
    meetingPassword,
    onMeetingEnd,
    history,
  ]);
  return (
    <div className="conference-call-container">
      <div className="logo-holder">
        <div className="logo">
          <a href="https://humantold.com/">
            <img
              src="https://humantold.com/wp-content/themes/humantold/assets/images/human-told-logo.svg?1604507125"
              alt="Humantold"
            />
          </a>
        </div>
      </div>
      <div id="conference-call-window"></div>
    </div>
  );
}

export default ConferenceCall;
