enum RecordType {
    PATIENT = 'patient'
}

export interface IWaitingRoomPatient {
    patient_id: string;
    patient_email: string;
    patient_name: string;
    since: string;
    status: WaitingRoomStatus;
}

export enum WaitingRoomStatus {
    WAITING = 'waiting',
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
    BUSY = 'busy'
}

export interface IProviderOption {
    copay: number;
    provider_email: string;
    provider_name: string;
    provider_id: string;
    // using this crude way for now
    password: string;
}

export interface IProviderOption {
    copay: number;
    provider_email: string;
    provider_name: string;
    provider_id: string;
    // using this crude way for now
    password: string;
}

export interface IPatient {
    id: string;
    name: string;
    email: string;
    type: RecordType;
    providers: Array<IProviderOption>
}

export interface IPaystackClientResponse {
    message: string,
    reference: string, // Same as what we sent.
    status: string,
    trans: string,
    transaction: string,
    trxref: string
}
