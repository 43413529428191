import { v5 as uuidv5 } from 'uuid';
import moment from 'moment';

const NAMESPACE = '383e96ed-2f80-44af-bc19-bc8017e25993' // TODO: Store in env.

export function generate_uuid(seed: string) {
    const id = uuidv5(seed, NAMESPACE)
    return id;
}

function get_today_date() {
    return moment().startOf("day").format('YYYY-MM-DD');
}

export function get_session_details(provider_email: string) {
    const todaysDate = get_today_date();
    return {
        meeting_id: generate_uuid(`${todaysDate}xprovider_session_${provider_email}`),
        meeting_password: generate_uuid(`${todaysDate}xprovider_session_password_${provider_email}`)
    };
}

export function get_waiting_room_identifier(provider_id: string) {
    return `/waiting_rooms/provider_${provider_id}/${get_today_date()}`
}

export const notificationAudio = new Audio(
    "https://proxy.notificationsounds.com/message-tones/pristine-609/download/file-sounds-1150-pristine.mp3"
  );