import { Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

function CountdownTimer({ targetTime }: { targetTime: Date }) {
  const calculateTimeLeft = () => {
    const difference = +targetTime - +new Date();
    let timeLeft: { [key: string]: number } = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents: JSX.Element[] = [];

  Object.keys(timeLeft).forEach((interval, idx) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={`span-${idx}`}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });
  return (
    <div>
      <Heading as="h4" size="sm">
        {timerComponents.length ? (
          timerComponents
        ) : (
          <span>
            Your provider isn't responding. Please contact{" "}
            <a href="mailto:support@humantold.com">support@humantold.com</a>
          </span>
        )}
      </Heading>
    </div>
  );
}

export default CountdownTimer;
