import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  Heading,
  Input,
  Select,
  Tag,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import { get_patient, update_waiting_room } from "../db/patient";
import { IPaystackClientResponse, IPatient } from "../types";
import { generate_uuid } from "../db/util";
import { useHistory } from "react-router-dom";

declare const window: any;

function PatientAccess() {
  const history = useHistory();
  const toast = useToast();
  const [patientEmail, setPatientEmail] = useState("");
  const [patient, setPatient] = useState({} as IPatient);
  const [providerId, setProviderId] = useState("");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.paystack.co/v1/inline.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const displayWarning = (title: string, message: string) => {
    toast({
      position: "top",
      title: title,
      description: message,
      status: "warning",
      duration: 6000,
      isClosable: true,
    });
  };

  const onPaymentClose = () => {
    displayWarning(
      "Payment Cancelled",
      "We detected that your payment was cancelled"
    );
  };

  const onPaymentCallback = (paymentResponse: IPaystackClientResponse) => {
    if (paymentResponse.status === "success") {
      // Add patient to waiting room.
      try {
        update_waiting_room(
          patient.id,
          patient.email,
          patient.name,
          providerId
        ).then((waiting_room) => {
          history.push(`/patient-waiting-room`, {
            patient,
            waiting_room,
            provider_id: providerId,
          });
        });
      } catch (ex) {
        console.error(ex);
        displayWarning("System error", "Please contact support");
      }
    } else {
      displayWarning(
        "Payment Failed",
        "We were unable to process your payment"
      );
    }
  };

  const proceedToPayment = async () => {
    const selectedProvider = patient.providers.find(
      (p) => p.provider_id === providerId
    );
    if (selectedProvider) {
      const paymentConfig = {
        ref: generate_uuid(`payment_${providerId}_${Date.now()}`),
        email: patient.email,
        amount: parseFloat(`${selectedProvider.copay}`) * 100,
        key: `pk_test_02c9373454be25747c75305c0428e802d5899c53`,
        currency: "NGN",
        firstname: patient.email,
        onClose: onPaymentClose,
        callback: onPaymentCallback,
      };

      const paymentHandler =
        window.PaystackPop && window.PaystackPop.setup(paymentConfig);
      paymentHandler.openIframe();
    }
  };

  return (
    <Grid minH="100vh" p={3}>
      <Container maxW="xl" centerContent mt="20">
        <VStack spacing={8}>
          <Box
            pt="5"
            pb="5"
            pl="10"
            pr="10"
            // bg="gray.50"
            minW="md"
            borderRadius="20px"
          >
            <Box textAlign="center">
              <Tag size="sm" colorScheme="teal">Demo Platform / Patient</Tag>
              <Heading as="h3" size="lg">
                Humantold
              </Heading>
            </Box>
            <Box my={4} textAlign="left">
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const patient = await get_patient(patientEmail);
                  if (!patient) {
                    displayWarning(
                      "No patient found",
                      "We cannot find any patient with that email address. Contact support@humantold.com"
                    );
                    return;
                  }
                  // Ask to select provider.
                  setPatient(patient);
                }}
              >
                <FormControl id="email" isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type="email"
                    value={patientEmail}
                    onChange={(e) => {
                      setPatientEmail(e.target.value);
                    }}
                  />
                  <FormHelperText>We need to know who you are</FormHelperText>
                </FormControl>
                {!patient.providers || patient.providers?.length < 1 ? (
                  <Button
                    mt={4}
                    type="submit"
                    rightIcon={<ArrowForwardIcon />}
                    colorScheme="teal"
                    variant="outline"
                  >
                    Enter
                  </Button>
                ) : (
                  <>
                    <FormControl id="slctProvider" mt={6}>
                      <FormLabel>Select Provider</FormLabel>
                      <Select
                        placeholder="Select option"
                        value={providerId || ""}
                        onChange={(e) => {
                          setProviderId(e.target.value);
                        }}
                      >
                        {patient.providers.map((providerOption, idx) => {
                          return (
                            <option
                              key={`provider-${idx}`}
                              value={providerOption.provider_id}
                            >
                              {providerOption.provider_name}
                            </option>
                          );
                        })}
                      </Select>
                      <FormHelperText>
                        We want to connect you to the right session
                      </FormHelperText>
                    </FormControl>
                    <Button
                      mt={4}
                      rightIcon={<ArrowForwardIcon />}
                      colorScheme="teal"
                      variant="outline"
                      onClick={async () => {
                        if (providerId) {
                          await proceedToPayment();
                        }
                      }}
                    >
                      Proceed to pay
                    </Button>
                  </>
                )}
              </form>
            </Box>
          </Box>
        </VStack>
      </Container>
    </Grid>
  );
}

export default PatientAccess;
