import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig = {
    // TODO: Use env
    apiKey: "AIzaSyByIhsCuUZ2kSvIyV6Q0emyedYrpN2LtRo",
    authDomain: "medcon-sandbox.firebaseapp.com",
    databaseURL: "https://medcon-sandbox.firebaseio.com",
    projectId: "medcon-sandbox",
    storageBucket: "medcon-sandbox.appspot.com",
    messagingSenderId: "290982641093",
    appId: "1:290982641093:web:7b311c4058d73cba7cdfac"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const database = firebase.database();

export { database };