import { BellIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Grid,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { database } from "../db/firebase";
import { update_waiting_room } from "../db/patient";
import { get_session_details, get_waiting_room_identifier, notificationAudio } from "../db/util";
import {
  IProviderOption,
  IWaitingRoomPatient,
  WaitingRoomStatus,
} from "../types";
import ConferenceCall from "./ConferenceCall";

function WaitingPatient({
  patient,
  provider_id,
}: {
  patient: IWaitingRoomPatient;
  provider_id: string;
}) {
  const update_patient_waiting_room = (status: WaitingRoomStatus) => {
    try {
      update_waiting_room(
        patient.patient_id,
        patient.patient_email,
        patient.patient_name,
        provider_id,
        status
      );
    } catch (ex) {
      console.error(ex);
    }
  };
  return (
    <Flex>
      <Avatar name={patient.patient_name} />
      <Box ml="1">
        <Text fontWeight="bold">{patient.patient_name}</Text>
        <HStack spacing={1}>
          <Box>
            <Button
              colorScheme="green"
              size="xs"
              onClick={(e) => {
                update_patient_waiting_room(WaitingRoomStatus.ACCEPTED);
              }}
            >
              Accept
            </Button>
          </Box>
          <Box>
            <Button
              colorScheme="red"
              size="xs"
              onClick={(e) => {
                update_patient_waiting_room(WaitingRoomStatus.REJECTED);
              }}
            >
              Reject
            </Button>
          </Box>
        </HStack>
      </Box>
    </Flex>
  );
}

function ProviderRoom() {
  const history = useHistory();
  const browserLocation = useLocation();
  const [provider] = useState(
    (browserLocation.state as any)?.provider as IProviderOption
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const meetingDetails = get_session_details(provider?.provider_email);
  const [waitingPatients, setWaitingPatients] = useState(
    [] as IWaitingRoomPatient[]
  );

  useEffect(() => {
    if (!provider) {
      history.push(`/`);
    }
  }, [provider, history]);

  useEffect(() => {
    if (!provider) return;
    const waitingRoomRef = database.ref(
      get_waiting_room_identifier(provider.provider_id)
    );
    waitingRoomRef.on("value", (snapshot) => {
      snapshot.forEach((childSnapshot) => {
        if (childSnapshot != null) {
          let incomingPatient = childSnapshot.val() as IWaitingRoomPatient;
          if (!incomingPatient) return;
          const patientStatus = incomingPatient.status;
          if (patientStatus === WaitingRoomStatus.WAITING) {
            // Add patient to the waiting room.
            notificationAudio.play();
            setWaitingPatients((w) => [
              ...w.filter((wp) => wp.patient_id !== incomingPatient.patient_id),
              incomingPatient,
            ]);
          } else if (
            [WaitingRoomStatus.ACCEPTED, WaitingRoomStatus.REJECTED].includes(
              patientStatus
            )
          ) {
            // Remove patient from the waiting room
            setWaitingPatients((w) =>
              w.filter((p) => p.patient_id !== incomingPatient.patient_id)
            );
          }
        }
      });
    });
  }, [provider]);

  return (
    <>
      <Drawer placement={"left"} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">Waiting Room</DrawerHeader>
            <DrawerBody>
              {provider && waitingPatients.length ? (
                waitingPatients.map((p) => (
                  <WaitingPatient
                    patient={p}
                    provider_id={provider.provider_id}
                  />
                ))
              ) : (
                <Text fontSize="md">No Patient at this time</Text>
              )}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Grid minH="100vh" p={3}>
        {provider && (
          <Container maxW="xl" centerContent>
            <VStack spacing={4} align="center">
              <Box h="40px">
                <Button
                  colorScheme="teal"
                  size="sm"
                  onClick={onOpen}
                  leftIcon={<BellIcon />}
                >
                  Waiting Room ({waitingPatients.length})
                </Button>
                <Divider pt="5" pb="5" orientation="horizontal" />
                <ConferenceCall
                  roomName={meetingDetails.meeting_id}
                  email={provider.provider_email}
                  displayName={provider.provider_name}
                  meetingPassword={meetingDetails.meeting_password}
                  isProvider={true}
                />
              </Box>
            </VStack>
          </Container>
        )}
      </Grid>
    </>
  );
}

export default ProviderRoom;
