import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import PatientAccess from "./components/PatientAccess";
import PatientSession from "./components/PatientSession";
import PatientWaitingRoom from "./components/PatientWaitingRoom";
import ProviderAccess from "./components/ProviderAccess";
import ProviderRoom from "./components/ProviderRoom";

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/patient-access">
            <PatientAccess />
          </Route>
          <Route path="/patient-session">
            <PatientSession />
          </Route>
          <Route path="/patient-waiting-room">
            <PatientWaitingRoom />
          </Route>
          <Route path="/provider-access">
            <ProviderAccess />
          </Route>
          <Route path="/provider-room">
            <ProviderRoom />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
