import {
  Box,
  Container,
  Divider,
  Grid,
  VStack
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { get_session_details } from "../db/util";
import { IPatient } from "../types";
import ConferenceCall from "./ConferenceCall";

function PatientSession() {
  const history = useHistory();
  const browserLocation = useLocation();
  const [patient] = useState(
    (browserLocation.state as any)?.patient as IPatient
  );
  const [provider_id] = useState(
    (browserLocation.state as any)?.provider_id as string
  );

  useEffect(() => {
    if (!patient || !provider_id) {
      history.push(`/`);
    }
  }, [patient, history, provider_id]);

  const provider =
    patient && patient.providers.find((p) => p.provider_id === provider_id);

  if (!provider) {
    return <div></div>;
  }

  const meetingDetails = get_session_details(provider?.provider_email);

  return (
    <Grid minH="100vh" p={3}>
      {patient && (
        <Container maxW="xl" centerContent>
          <VStack spacing={4} align="center">
            <Box h="40px">
              <Divider pt="5" pb="5" orientation="horizontal" />
              <ConferenceCall
                roomName={meetingDetails.meeting_id}
                email={patient.email}
                displayName={patient.name}
                meetingPassword={meetingDetails.meeting_password}
              />
            </Box>
          </VStack>
        </Container>
      )}
    </Grid>
  );
}

export default PatientSession;
