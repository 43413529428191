import { IProviderOption, WaitingRoomStatus, IWaitingRoomPatient } from "../types";
import { database } from "./firebase";
import { generate_uuid, get_waiting_room_identifier } from "./util";
import moment from 'moment';
import ProviderAccess from "../components/ProviderAccess";

export async function get_provider(email_id: string): Promise<IProviderOption | undefined> {
    const identifier = generate_uuid(email_id);
    const provider_doc = await database.ref(`providers/provider_${identifier}`).once('value');
    if (provider_doc.exists()) {
        return provider_doc.val() as IProviderOption
    }
    return;
}

export async function get_waiting_room(provider_id: string):  Promise<IWaitingRoomPatient | undefined> {
    const waiting_room = await database.ref(`${get_waiting_room_identifier(provider_id)}`).once('value');
    if (waiting_room.exists()) {
        return waiting_room.val() as IWaitingRoomPatient;
    }
    return;
}