import { IPatient, WaitingRoomStatus } from "../types";
import { database } from "./firebase";
import { generate_uuid, get_waiting_room_identifier } from "./util";


export async function get_patient(email_id: string): Promise<IPatient | undefined> {
    const identifier = generate_uuid(email_id);
    const patient_doc = await database.ref(`patients/patient_${identifier}`).once('value');
    if (patient_doc.exists()) {
        return patient_doc.val() as IPatient
    }
    return;
}

export async function update_waiting_room(patient_id: string, patient_email: string, patient_name: string,
provider_id: string, conferenceStatus: WaitingRoomStatus=WaitingRoomStatus.WAITING):  Promise<string> {
    const waiting_room = `${get_waiting_room_identifier(provider_id)}/${patient_id}`;
    await database.ref().update({ [waiting_room]: { patient_id: patient_id, patient_email, patient_name, since: new Date(), status: conferenceStatus } })
    return waiting_room;
}