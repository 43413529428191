import {
  Box,
  Container,
  Grid,
  Heading,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { database } from "../db/firebase";
import { notificationAudio } from "../db/util";
import { IPatient, IWaitingRoomPatient, WaitingRoomStatus } from "../types";
import CountdownTimer from "./CountdownTimer";

function PatientWaitingRoom() {
  const toast = useToast();
  const history = useHistory();
  const browserLocation = useLocation();
  const [targetTime] = useState(moment().add(15, "minutes").toDate());
  const [waiting_room] = useState(
    (browserLocation.state as any)?.waiting_room as string
  );
  const [patient] = useState(
    (browserLocation.state as any)?.patient as IPatient
  );
  const [provider_id] = useState(
    (browserLocation.state as any)?.provider_id as string
  );

  useEffect(() => {
    if (!patient || !waiting_room) {
      history.push(`/`);
    }
  }, [patient, waiting_room, history]);

  useEffect(() => {
    const waitingRoomRef = database.ref(waiting_room);
    waitingRoomRef.on("value", (snapshot) => {
      const waitingPatient = snapshot.val() as IWaitingRoomPatient;
      if (waitingPatient.status === WaitingRoomStatus.ACCEPTED) {
        notificationAudio.play();
        history.push(`/patient-session`, { patient, provider_id });
      } else if (waitingPatient.status === WaitingRoomStatus.REJECTED) {
        toast({
          position: "top",
          title: "Access denied",
          description:
            "Access to session denied, please contact support@humantold.com",
          status: "warning",
          duration: 6000,
          isClosable: true,
          onCloseComplete: () => {
            history.push(`/patient-access`);
          },
        });
      }
    });
  }, [waiting_room, toast, history, patient]);

  return (
    <Grid minH="100vh" p={3}>
      <Container maxW="xl" centerContent mt="20">
        <VStack spacing={4} align="center">
          <Box h="40px">
            <Heading as="h3" size="md">
              Please wait for your provider to let you in.
            </Heading>
          </Box>
          <Box h="40px">
            <CountdownTimer targetTime={targetTime} />
          </Box>
          <Box h="40px">
            <Spinner
              thickness="4px"
              speed="0.8s"
              emptyColor="gray.200"
              color="teal.500"
              size="xl"
            />
          </Box>
        </VStack>
        <VStack spacing={4} mt="20" align="center">
          <Text as="sup" fontSize="xs">Prevent the spread of COVID-19, follow recommendations of health and safety officials ❤️</Text>
        </VStack>
      </Container>
    </Grid>
  );
}

export default PatientWaitingRoom;
