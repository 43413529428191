import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useToast,
  Container,
  Tag,
  VStack,
  Grid,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { get_provider } from "../db/provider";
import { IProviderOption } from "../types";
import { useHistory } from "react-router-dom";

function ProviderAccess() {
  const history = useHistory();
  const toast = useToast();
  const [providerEmail, setProviderEmail] = useState("");
  const [provider, setProvider] = useState({} as IProviderOption);
  const [password, setPassword] = useState("");

  const displayWarning = (title: string, message: string) => {
    toast({
      position: "top",
      title: title,
      description: message,
      status: "warning",
      duration: 6000,
      isClosable: true,
    });
  };

  return (
    <Grid minH="100vh" p={3}>
      <Container maxW="xl" centerContent mt="20">
        <VStack spacing={8}>
          <Box
            pt="5"
            pb="5"
            pl="10"
            pr="10"
            // bg="gray.50"
            minW="md"
            borderRadius="20px"
          >
            <Box textAlign="center">
              <Tag size="sm" colorScheme="teal">
                Demo Platform / Provider
              </Tag>
              <Heading as="h3" size="lg">
                Humantold
              </Heading>
            </Box>
            <Box my={4} textAlign="left">
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const provider = await get_provider(providerEmail);
                  if (!provider || password !== provider.password) {
                    displayWarning(
                      "No provider found",
                      "Your username or password is invalid"
                    );
                    return;
                  }
                  setProvider(provider);
                  history.push(`/provider-room`, {
                    provider,
                  });
                }}
              >
                <FormControl isRequired>
                  <FormLabel>Email address</FormLabel>
                  <Input
                    type="email"
                    value={providerEmail}
                    onChange={(e) => {
                      setProviderEmail(e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl isRequired mt={4}>
                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </FormControl>
                <Button
                  mt={4}
                  type="submit"
                  rightIcon={<ArrowForwardIcon />}
                  colorScheme="teal"
                  variant="outline"
                >
                  Login
                </Button>
              </form>
            </Box>
          </Box>
        </VStack>
      </Container>
    </Grid>
  );
}

export default ProviderAccess;
