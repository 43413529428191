import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Grid,
  Heading,
  Tag,
  VStack,
  Link,
  Avatar,
  TagLabel,
  HStack,
  Badge,
  Text,
  Flex,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import React from "react";

function Home() {
  return (
    <Grid minH="100vh" p={3}>
      <Container maxW="xl" centerContent mt="20">
        <VStack spacing={8}>
          <Box
            pt="5"
            pb="5"
            pl="10"
            pr="10"
            // bg="gray.50"
            minW="md"
            borderRadius="20px"
          >
            <Box textAlign="center">
              <Tag size="sm" colorScheme="teal">
                Demo Platform
              </Tag>
              <Heading as="h3" size="lg">
                Humantold
              </Heading>
            </Box>
            <HStack mt="20" spacing={20}>
              <Box textAlign="left">
                <Flex>
                  <Avatar src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairStraight&accessoriesType=Prescription01&hairColor=Platinum&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Pale" />
                  <Box ml="3">
                    <Text fontWeight="bold">Seeing a patient?</Text>
                    <RouterLink to="/provider-access">
                      <Link>
                        Provider Login <ArrowForwardIcon />
                      </Link>
                    </RouterLink>
                  </Box>
                </Flex>
              </Box>
              <Box  textAlign="left">
                <Flex>
                  <Avatar src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairCurly&accessoriesType=Blank&hairColor=Black&facialHairType=Blank&clotheType=ShirtVNeck&clotheColor=Heather&eyeType=Happy&eyebrowType=Default&mouthType=Default&skinColor=Brown" />
                  <Box ml="3">
                    <Text fontWeight="bold">Seeing a provider?</Text>
                    <RouterLink to="/patient-access">
                      <Link>
                        Patient Page <ArrowForwardIcon />
                      </Link>
                    </RouterLink>
                  </Box>
                </Flex>
              </Box>
            </HStack>
          </Box>
        </VStack>
      </Container>
    </Grid>
  );
}

export default Home;
